var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var P3,R3,T3,U3,V3,W3,X3,Fra;P3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=P3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=P3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.Q3=function(a,b){return P3($CLJS.b_(a),b)};
R3=function(a,b,c){var d=$CLJS.EV(a,b);d=$CLJS.O(d);d=$CLJS.K.g(d,$CLJS.YE);d=$CLJS.sG($CLJS.hk.j($CLJS.Tg([c]),$CLJS.wD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.fD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.sr,c,$CLJS.EE,a,$CLJS.FK,b],null));return d};$CLJS.S3=function(a){return $CLJS.G1($CLJS.uF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};T3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
U3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);V3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);W3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);X3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Fra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.O_.m(null,$CLJS.YE,function(a,b){var c=$CLJS.Be($CLJS.YE.h($CLJS.EV(a,b)));return $CLJS.n(c)?$CLJS.J1($CLJS.HD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.id(l,u);v=$CLJS.k_.v(a,b,v,$CLJS.l_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.k_.v(a,
b,t,$CLJS.l_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.R_.m(null,$CLJS.YE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.tA);e=$CLJS.K.g(e,$CLJS.oi);c=$CLJS.I(c,2,null);c=R3(a,b,c);return $CLJS.zk.l($CLJS.H([$CLJS.i_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.MJ,$CLJS.lK,$CLJS.C0,$CLJS.wD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.oi,e],null):null]))});
$CLJS.L_.m(null,$CLJS.YE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.k_.v(a,b,R3(a,b,c),d)});$CLJS.oE(V3,X3);
for(var Y3=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.jF,$CLJS.rF],null)),Z3=null,$3=0,a4=0;;)if(a4<$3){var Gra=Z3.X(null,a4);$CLJS.oE(Gra,V3);a4+=1}else{var b4=$CLJS.y(Y3);if(b4){var c4=b4;if($CLJS.wd(c4)){var d4=$CLJS.ic(c4),Hra=$CLJS.jc(c4),Ira=d4,Jra=$CLJS.D(d4);Y3=Hra;Z3=Ira;$3=Jra}else{var Kra=$CLJS.z(c4);$CLJS.oE(Kra,V3);Y3=$CLJS.B(c4);Z3=null;$3=0}a4=0}else break}
$CLJS.L_.m(null,V3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.k_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.fD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.fD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.HD("Count");case "cum-count":return $CLJS.HD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.M_.m(null,V3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.R_.m(null,W3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.lH($CLJS.R_,X3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.tj,$CLJS.TB)});
$CLJS.oE(W3,X3);$CLJS.oE(V3,W3);$CLJS.oE($CLJS.NE,W3);$CLJS.L_.m(null,$CLJS.RE,function(){return $CLJS.HD("Case")});$CLJS.M_.m(null,$CLJS.RE,function(){return"case"});$CLJS.oE(U3,X3);
for(var e4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.cF,$CLJS.EF,$CLJS.NE,$CLJS.Cj,$CLJS.vF,$CLJS.si,$CLJS.xF,$CLJS.uF,$CLJS.OE],null)),f4=null,g4=0,h4=0;;)if(h4<g4){var Lra=f4.X(null,h4);$CLJS.oE(Lra,U3);h4+=1}else{var i4=$CLJS.y(e4);if(i4){var j4=i4;if($CLJS.wd(j4)){var k4=$CLJS.ic(j4),Mra=$CLJS.jc(j4),Nra=k4,Ora=$CLJS.D(k4);e4=Mra;f4=Nra;g4=Ora}else{var Pra=$CLJS.z(j4);$CLJS.oE(Pra,U3);e4=$CLJS.B(j4);f4=null;g4=0}h4=0}else break}
$CLJS.M_.m(null,U3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.L_.m(null,U3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.k_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.fD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.fD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.fD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.fD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.fD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.fD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.fD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.fD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.fD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.L_.m(null,$CLJS.UE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.fD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.k_.v(a,b,e,d)]))});$CLJS.M_.m(null,$CLJS.UE,function(){return"percentile"});
$CLJS.oE(T3,X3);for(var l4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UE,$CLJS.OE],null)),m4=null,n4=0,o4=0;;)if(o4<n4){var Qra=m4.X(null,o4);$CLJS.oE(Qra,T3);o4+=1}else{var p4=$CLJS.y(l4);if(p4){var q4=p4;if($CLJS.wd(q4)){var r4=$CLJS.ic(q4),Rra=$CLJS.jc(q4),Sra=r4,Tra=$CLJS.D(r4);l4=Rra;m4=Sra;n4=Tra}else{var Ura=$CLJS.z(q4);$CLJS.oE(Ura,T3);l4=$CLJS.B(q4);m4=null;n4=0}o4=0}else break}
$CLJS.R_.m(null,T3,function(a,b,c){var d=$CLJS.ak.g,e=$CLJS.lH($CLJS.R_,X3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.ak,a,$CLJS.tj)});$CLJS.L_.m(null,$CLJS.CF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.fD("Sum of {0} matching condition",$CLJS.H([$CLJS.k_.v(a,b,e,d)]))});
$CLJS.M_.m(null,$CLJS.CF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.N_.j(a,b,d))].join("")});$CLJS.oE($CLJS.CF,X3);$CLJS.L_.m(null,$CLJS.pF,function(){return $CLJS.HD("Share of rows matching condition")});$CLJS.M_.m(null,$CLJS.pF,function(){return"share"});$CLJS.R_.m(null,$CLJS.pF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.lH($CLJS.R_,X3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.tj,$CLJS.kC)});
$CLJS.oE($CLJS.pF,X3);$CLJS.L_.m(null,$CLJS.rF,function(){return $CLJS.HD("Count of rows matching condition")});$CLJS.R_.m(null,X3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.zk.l;d=$CLJS.n(d)?$CLJS.Bl($CLJS.i_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,$CLJS.tj],null)):null;var f=$CLJS.lH($CLJS.R_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.zk,$CLJS.H([d,a]))});
$CLJS.Vra=function(){function a(d){return $CLJS.G1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.G1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.nV.m(null,$CLJS.YE,function(a){return a});
$CLJS.s4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.XA(f),$CLJS.QJ))f=$CLJS.pV(f);else return $CLJS.L1(d,e,$CLJS.YE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.t4=function(){function a(d,e){return $CLJS.Be($CLJS.YE.h($CLJS.EV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.u4=function(){function a(d,e){var f=$CLJS.Be($CLJS.YE.h($CLJS.EV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.i_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.F1(l,$CLJS.oi,$CLJS.n(t)?t:$CLJS.dj),$CLJS.MJ,$CLJS.lK,$CLJS.H([$CLJS.C0,$CLJS.wD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.L_.m(null,$CLJS.QF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.K.g(a,$CLJS.MF);return $CLJS.kD.h(a.o?a.o():a.call(null))});$CLJS.S_.m(null,$CLJS.QF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.K.g(d,$CLJS.bF);b=$CLJS.K.g(d,$CLJS.MF);c=$CLJS.K.g(d,$CLJS.LF);d=$CLJS.K.g(d,$CLJS.LY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.J_,$CLJS.$z(a),$CLJS.H([$CLJS.H_,c]));return null!=d?$CLJS.R.j(a,$CLJS.s_,d):a});
$CLJS.Wra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.Y0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.EV(d,e),l=$CLJS.X_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.hk.j($CLJS.ef(function(m){m=$CLJS.OF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.tl(function(m){m=$CLJS.O(m);var t=$CLJS.K.g(m,$CLJS.LF),u=$CLJS.K.g(m,$CLJS.JF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.ur))return $CLJS.R.j(m,$CLJS.BV,l);t=$CLJS.Be($CLJS.x0(function(v){return $CLJS.BZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.BV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Vs,$CLJS.QF)})),$CLJS.RF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Xra=function(){function a(d,e){return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF.h(d),$CLJS.N,$CLJS.qV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.LF.h(d)))return $CLJS.oV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF.h(d),$CLJS.N],null));var e=$CLJS.bF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Fra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();